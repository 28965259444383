// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.my-logo {
  position: relative;
  background-color: #000 !important;
}

.my-logo img {
  position: relative;
    left: 0;
    width: 75%;
    right: 0;
    bottom: 0;
    text-align: center;
    top: 0;
}
