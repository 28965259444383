.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  padding: .5em .8em .5em .5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: .5em;
  margin-right: .5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}
