// Here you can add other styles

.tox .tox-dialog-wrap__backdrop--opaque {
    background-color: rgba(255,255,255,.75) !important;
}

.form-check-input {
    width: 2em;
    height: 2em;
}

[data-field="active"],
[data-field="klas"],
[data-field="delete"],
[data-field="action"] {
    justify-content: center !important;
}

// Задній фон про відкритті модального вікна
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.css-1l9ohcm {
    box-shadow: none !important;
}

.calc-input {
    display: flex;
    gap: 20px;
}